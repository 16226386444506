import React from "react";

import Slider from "../Slider";

export default function SwapSetting({
    errors,
    touched,
    setFieldValue,
    values,
}) {
    const gasPriceSpeed = {
        1: "Standard",
        2: "Fast",
        3: "Flash",
        4: "Zap",
        5: "Instant",
    };

    return (
        <>
            <div className="flex flex-col gap-2 mb-5">
                <div>
                    Slippage Tolerance: {parseFloat(values.slippage).toFixed(1)}
                    %
                </div>
                <Slider
                    min="0.5"
                    max="5"
                    step="0.5"
                    name="slippage"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                />
            </div>
            <div className="flex flex-col gap-2">
                <div>
                    Gas Price (Gwei): {values.gasPrice} (
                    {gasPriceSpeed[values.gasPrice]})
                </div>
                <Slider
                    min="1"
                    max="5"
                    step="1"
                    name="gasPrice"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                />
            </div>
        </>
    );
}
