import React from "react";

import { Field } from "formik";

export default function Slider({ min, max, step, name, setFieldValue }) {
    function handleChange(e) {
        setFieldValue(name, e.target.value);
        localStorage.setItem(name, e.target.value);
    }

    return (
        <div>
            <Field
                type="range"
                min={min}
                max={max}
                step={step}
                name={name}
                onChange={(e) => handleChange(e)}
                className="w-full appearance-none h-4 bg-[#0c0f13] rounded-full [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:bg-[#3677ef] [&::-webkit-slider-thumb:hover]:bg-[#3B83FF] [&::-webkit-slider-thumb]:duration-100 [&::-webkit-slider-thumb]:h-5 [&::-webkit-slider-thumb]:w-5 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:cursor-pointer"
            />
        </div>
    );
}
