import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useAccount } from "wagmi";

import TokenSelector from "../TokenSelector";
import SwapButton from "../SwapButton";
import SwapSetting from "../SwapSetting";
import SwapSummary from "../SwapSummary";

import { tokenSwapSchema } from "../../assets/schema/tokenSwapSchema";

import { PancakeSwapLogo } from "../../assets/img";

export default function DexSwapModal() {
    const defaultSlippage = localStorage.getItem("slippage") || 2.0;
    const defaultGasPrice = localStorage.getItem("gasPrice") || 4;

    const [fromTokenAddress, setFromTokenAddress] = useState(null);
    const [toTokenAddress, setToTokenAddress] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const FROM_TOKEN_ADDRESS = "fromToken";
    const TO_TOKEN_ADDRESS = "toToken";

    const account = useAccount();

    function handleFromTokenChange(tokenAddress) {
        searchParams.set(FROM_TOKEN_ADDRESS, tokenAddress);
        setSearchParams(searchParams);
        setFromTokenAddress(tokenAddress);
    }

    function handleToTokenChange(tokenAddress) {
        searchParams.set(TO_TOKEN_ADDRESS, tokenAddress);
        setSearchParams(searchParams);
        setToTokenAddress(tokenAddress);
    }

    function handleSwapTokenButtonClick() {
        const fromToken = searchParams.get(FROM_TOKEN_ADDRESS);
        const toToken = searchParams.get(TO_TOKEN_ADDRESS);

        setSearchParams({
            [FROM_TOKEN_ADDRESS]: toToken,
            [TO_TOKEN_ADDRESS]: fromToken,
        });

        setFromTokenAddress(toToken);
        setToTokenAddress(fromToken);
    }

    useEffect(() => {
        const fromToken = searchParams.get(FROM_TOKEN_ADDRESS);
        const toToken = searchParams.get(TO_TOKEN_ADDRESS);

        const isFromTokenValid = fromToken && fromToken.length === 42;
        const isToTokenValid = toToken && toToken.length === 42;

        if ((!isFromTokenValid && !isToTokenValid) || fromToken === toToken) {
            searchParams.set(
                [FROM_TOKEN_ADDRESS],
                "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
            );
            searchParams.set(
                [TO_TOKEN_ADDRESS],
                "0x443cab9583b83eaa7a712c9d64525e57e2a7eb3f"
            );
            setSearchParams(searchParams);

            setFromTokenAddress("0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c");
            setToTokenAddress("0x443cab9583b83eaa7a712c9d64525e57e2a7eb3f");

            return;
        }

        if (!isFromTokenValid) {
            searchParams.set(
                [FROM_TOKEN_ADDRESS],
                "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
            );
            setSearchParams(searchParams);

            setFromTokenAddress("0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c");

            return;
        }

        if (!isToTokenValid) {
            searchParams.set(
                [TO_TOKEN_ADDRESS],
                "0x443cab9583b83eaa7a712c9d64525e57e2a7eb3f"
            );
            setSearchParams(searchParams);

            setToTokenAddress("0x443cab9583b83eaa7a712c9d64525e57e2a7eb3f");

            return;
        }

        setFromTokenAddress(fromToken);
        setToTokenAddress(toToken);
    }, []);

    return (
        <div className="rounded-xl bg-[#141820] flex flex-col p-3 pb-10">
            <div className="flex gap-3 justify-center items-center font-bold text-xl mb-10">
                <img src={PancakeSwapLogo} className="w-6" />
                Swap (PancakeSwap Exchange)
            </div>
            <Formik
                initialValues={{
                    fromTokenAmount: "",
                    toTokenAmount: "",
                    slippage: defaultSlippage,
                    gasPrice: defaultGasPrice,
                }}
                validationSchema={tokenSwapSchema}
                validateOnChange={true}
                onSubmit={(values) => {
                    console.log(values);
                }}
            >
                {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                    <Form className="flex w-full gap-12">
                        <div className="w-64">
                            <TokenSelector
                                tokenAddress={fromTokenAddress}
                                handleTokenSelect={handleFromTokenChange}
                                name="fromTokenAmount"
                                errors={errors}
                                touched={touched}
                                value={1000}
                            />
                            <div className="flex justify-center py-3">
                                <SwapButton
                                    onClick={handleSwapTokenButtonClick}
                                />
                            </div>
                            <TokenSelector
                                tokenAddress={toTokenAddress}
                                handleTokenSelect={handleToTokenChange}
                                name="toTokenAmount"
                                errors={errors}
                                touched={touched}
                                value={1200}
                                isToToken={true}
                            />
                            <SwapSetting
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                values={values}
                            />
                        </div>
                        <div className="flex flex-col w-56 justify-between">
                            <SwapSummary
                                minimumReceived={5000}
                                tokenSymbol="XTM"
                                priceBefore={1.6231232}
                                priceAfter={1.45467}
                                swapFee={3.54545}
                                routeArray={[
                                    "USDT",
                                    "USDC",
                                    "TUSD",
                                    "BNB",
                                    "XTM",
                                ]}
                            />
                            {account.isConnected ? (
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="bg-[#3677ef] hover:bg-[#3B83FF] duration-100 rounded-xl py-2 w-full text-white text-lg font-semibold"
                                >
                                    Swap
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    onClick={null}
                                    className="bg-gray-400 hover:bg-gray-500 duration-100 rounded-xl py-2 w-full text-white text-lg font-semibold"
                                >
                                    Connect Wallet
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
