import { getDefaultConfig } from "connectkit";
import { http, createConfig, fallback } from "wagmi";
import { bsc } from "wagmi/chains";

export const wagmiConfig = createConfig(
    getDefaultConfig({
        appName: "My RainbowKit App",
        walletConnectProjectId: "f2f61ab5a7227bab27c1c172e6e70414",
        chains: [bsc],
        transports: {
            [bsc.id]: fallback([
                http("https://bsc-dataseed.binance.org/", {
                    // retryCount: 3,
                    retryDelay: 100,
                    timeout: 3000,
                }),

                http("https://rpc.ankr.com/bsc", {
                    retryDelay: 100,
                    timeout: 3000,
                }),

                http("https://bsc-rpc.publicnode.com", {
                    retryDelay: 100,
                    timeout: 3000,
                }),

                http("https://binance.llamarpc.com", {
                    retryDelay: 100,
                    timeout: 3000,
                }),
            ]),
        },
    })
);
