import React from "react";

export default function SwapButton({ onClick }) {
    const [isMouseHover, setIsMouseHover] = React.useState(false);

    return (
        <div
            className="rounded-full bg-[#3677ef] hover:bg-[#3B83FF] duration-100 w-8 h-8 flex justify-center items-center pt-1.5 cursor-pointer"
            onMouseEnter={(e) => setIsMouseHover(true)}
            onMouseLeave={(e) => setIsMouseHover(false)}
            onClick={onClick}
        >
            {isMouseHover ? (
                <i className="fi fi-br-sort-alt text-xl text-white" />
            ) : (
                <i className="fi fi-br-arrow-down text-xl text-white" />
            )}
        </div>
    );
}
