import React from "react";
import { TorumLogo } from "../../assets/img";

export default function PriceAverageModal({
    price,
    volume,
    high,
    low,
    contract,
    marketCap,
    totalSupply,
    circulatingSupply,
}) {
    function formatNumber(number) {
        return new Intl.NumberFormat("en-US").format(number);
    }

    async function copyContractToClipboard(e, contract) {
        try {
            const permissions = await navigator.permissions.query({
                name: "clipboard-write",
            });
            if (
                permissions.state === "granted" ||
                permissions.state === "prompt"
            ) {
                await navigator.clipboard.writeText(contract);
            } else {
                throw new Error(
                    "Can't access the clipboard. Check your browser permissions."
                );
            }
        } catch (error) {}
    }

    return (
        <div className="p-3 rounded-xl bg-[#141820] hover:bg-[#1a202a] duration-150 grid grid-cols-2">
            <div className="flex flex-col justify-center gap-5 pl-5">
                <div className="text-5xl font-bold items-center flex">
                    ${formatNumber(price)}
                </div>
                <div className="flex items-center">
                    <img
                        src={TorumLogo}
                        alt="Torum Logo"
                        className="h-12 w-11"
                    />
                    <a
                        href="https://www.torum.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="ml-5 font-semibold text-3xl hover:underline">
                            Torum (XTM)
                        </div>
                    </a>
                    <div className="h-full flex items-center">
                        <i className="fi fi-br-link text-xl ml-1 pt-2" />
                    </div>
                </div>
            </div>
            <div className="flex justify-center gap-5">
                <div className="flex flex-col justify-center">
                    <div>High</div>
                    <div>Low</div>
                    <div>24h Vol</div>
                    <div>Market Cap</div>
                    <div>Total Supply</div>
                    <div>Contract</div>
                </div>
                <div className="flex flex-col justify-center">
                    <div>${formatNumber(high)}</div>
                    <div>${formatNumber(low)}</div>
                    <div>${formatNumber(volume)}</div>
                    <div>${formatNumber(marketCap)}</div>
                    <div>{formatNumber(totalSupply)} XTM</div>
                    <div className="flex items-center">
                        <div
                            className="hover:underline hover:cursor-pointer active:text-green-500 duration-150"
                            onClick={(e) =>
                                copyContractToClipboard(e, contract)
                            }
                        >
                            {contract.substring(0, 6)}
                            ...
                            {contract.substring(contract.length - 4)}
                        </div>
                        <i className="fi fi-br-copy text-xs ml-2 pt-1" />
                    </div>
                </div>
            </div>
        </div>
    );
}
