import React, { useState, useEffect } from "react";
import { Field } from "formik";
import { useAccount, useBalance } from "wagmi";

import TokenSelectorModal from "../TokenSelectorModal";

import { getTokenInfo } from "../../api/TokenInfo";
import { formatEther } from "viem";

export default function TokenSelector({
    value,
    tokenAddress,
    handleTokenSelect,
    name,
    errors,
    touched,
    isToToken,
}) {
    const [isTokenSelectorModalOpen, setIsTokenSelectorModalOpen] =
        useState(false);

    const [tokenInfo, setTokenInfo] = useState(null);
    const [tokenBalance, setTokenBalance] = useState(null);

    useEffect(() => {
        (async function () {
            const tokenInfo = await getTokenInfo(tokenAddress);
            setTokenInfo(tokenInfo);
        })();
    }, [tokenAddress]);

    const account = useAccount();
    const { data } = useBalance({
        address: account.address,
        token: tokenAddress,
    });

    useEffect(() => {
        if (data) {
            setTokenBalance(formatEther(data.value));
        }
    }, [data]);

    function formatNumber(number) {
        return new Intl.NumberFormat("en-US").format(number);
    }

    return (
        <>
            {isTokenSelectorModalOpen && (
                <TokenSelectorModal
                    handleClose={() => setIsTokenSelectorModalOpen(false)}
                    handleTokenSelect={(tokenAddress) => {
                        handleTokenSelect(tokenAddress);
                    }}
                    isToToken={isToToken}
                />
            )}
            <div className="flex mx-1 justify-between">
                <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setIsTokenSelectorModalOpen(true)}
                >
                    {tokenInfo?.logoURI ? (
                        <img
                            src={tokenInfo?.logoURI}
                            className="w-4 h-4 rounded-full"
                        />
                    ) : (
                        <i className="fi fi-br-interrogation text-md mb-1 w-4 h-4" />
                    )}
                    <div className="ml-1 text-lg">{tokenInfo?.symbol}</div>
                    <i className="fi fi-br-caret-down ml-1 text-base mt-1" />
                </div>
                <div className="flex items-end text-sm">
                    Balance: {tokenBalance ? formatNumber(tokenBalance) : 0}
                </div>
            </div>
            <Field
                type="text"
                name={name}
                className="bg-[#0c0f13] rounded-xl h-10 w-full text-lg px-2 my-1 text-right font-semibold focus:outline-1 focus:outline-[#162642] focus:outline-none"
                placeholder="0"
            />
            <div className="mr-1 h-10 text-sm justify-end flex">
                {errors[name] && touched[name] ? (
                    <div className="text-red-600">{errors[name]}</div>
                ) : (
                    <div>~ ${value ? formatNumber(value) : 0}</div>
                )}
            </div>
        </>
    );
}
