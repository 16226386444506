import React from "react";
import { Link, useLocation } from "react-router-dom";

import { ConnectKitButton } from "connectkit";

import { TorumLogoWithText } from "../../assets/img";

export default function Navbar() {
    // const location = useLocation().pathname;

    return (
        <div className="flex justify-between items-center mx-5 my-3 h-9">
            <div className="h-full">
                <Link to="/">
                    <img
                        src={TorumLogoWithText}
                        alt="Torum Logo"
                        className="h-full"
                    />
                </Link>
            </div>
            <div className="flex space-x-16 items-center">
                {/* <Link
                    to="/"
                    className={
                        location === "/"
                            ? "navbar__link navbar__link--active"
                            : "navbar__link"
                    }
                >
                    Home
                </Link>
                <Link
                    to="/about"
                    className={
                        location === "/about"
                            ? "navbar__link navbar__link--active"
                            : "navbar__link"
                    }
                >
                    About
                </Link> */}
                <ConnectKitButton />
            </div>
        </div>
    );
}
