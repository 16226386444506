import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Web3Provider } from "./components/Web3Provider/index.jsx";
import { SpeedInsights } from "@vercel/speed-insights/react";

import "./assets/css/root.css";

import Homepage from "./pages/Homepage";
// import About from "./pages/About";

function App() {
    return (
        <Web3Provider>
            <SpeedInsights />
            <Router>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    {/* <Route path="/about" element={<About />} /> */}
                    <Route path="*" element={<Homepage />} />
                </Routes>
            </Router>
        </Web3Provider>
    );
}

export default App;
