import React from "react";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { wagmiConfig } from "../../assets/config/wagmi.ts";
import { ConnectKitProvider } from "connectkit";

const queryClient = new QueryClient();

export function Web3Provider({ children }) {
    return (
        <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={queryClient}>
                <ConnectKitProvider mode="dark">{children}</ConnectKitProvider>
            </QueryClientProvider>
        </WagmiProvider>
    );
}
