import React from "react";

import PriceAverageModal from "../PriceAverageModal";
import PriceModalByMarket from "../PriceModalByMarket";

import {
    PancakeSwapLogo,
    HTXLogo,
    KucoinLogo,
    MEXCLogo,
} from "../../assets/img";

export default function TokenPriceDashboard() {
    return (
        <div className="grid grid-cols-2 gap-2">
            <PriceAverageModal
                price="0.05"
                volume="1000"
                high="0.06"
                low="0.04"
                contract="0x443cab9583b83eaa7a712c9d64525e57e2a7eb3f"
                marketCap="1000000"
                totalSupply="800000000"
            />
            <div className="grid grid-cols-2 grid-rows-2 gap-2">
                <PriceModalByMarket
                    logo={PancakeSwapLogo}
                    name="Pancake Swap"
                    price="0.05"
                    volume="1000"
                    volumePercent="25.0"
                />
                <PriceModalByMarket
                    logo={HTXLogo}
                    name="Huobi"
                    price="0.01"
                    volume="2000"
                    volumePercent="50.0"
                />
                <PriceModalByMarket
                    logo={KucoinLogo}
                    name="Kucoin"
                    price="0.02"
                    volume="3000"
                    volumePercent="75.0"
                />
                <PriceModalByMarket
                    logo={MEXCLogo}
                    name="MEXC"
                    price="0.03"
                    volume="4000"
                    volumePercent="30.0"
                />
            </div>
        </div>
    );
}
