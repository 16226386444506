import React from "react";

import Navbar from "../../components/Navbar";
import TokenPriceDashboard from "../../components/TokenPriceDashboard";
import DexSwapModal from "../../components/DexSwapModal";

export default function Homepage() {
    return (
        <>
            <Navbar />
            <div className="px-4">
                <TokenPriceDashboard />
            </div>
            <div className="px-4 py-2 justify-center flex">
                <DexSwapModal />
            </div>
        </>
    );
}
