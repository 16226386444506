import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { getAllTokenInfoFromAPI } from "../../api/TokenInfo";
import { TorumLogo } from "../../assets/img";

export default function TokenSelectorModal({
    handleClose,
    handleTokenSelect,
    isToToken,
}) {
    const [isVisible, setIsVisible] = useState(false);
    const [tokenSearchInput, setTokenSearchInput] = useState("");
    const [lastTokenSearchInput, setlastTokenSearchInput] = useState("");
    const [allTokenInfoList, setAllTokenInfoList] = useState([]);
    const [filteredTokenInfoList, setFilteredTokenInfoList] = useState([]);

    const trustedTokenList = [
        // {
        //     address: "BNB",
        //     name: "Binance Chain Token",
        //     symbol: "BNB",
        //     logoURI:
        //         "https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png",
        // },
        {
            address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            name: "Wrapped BNB",
            symbol: "WBNB",
            logoURI:
                "https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png",
        },
        {
            address: "0x443cab9583b83eaa7a712c9d64525e57e2a7eb3f",
            name: "Torum",
            symbol: "XTM",
            logoURI: TorumLogo,
        },
        {
            address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
            name: "USD Coin",
            symbol: "USDC",
            logoURI:
                "https://tokens.pancakeswap.finance/images/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d.png",
        },
        {
            address: "0x55d398326f99059ff775485246999027b3197955",
            name: "Tether",
            symbol: "USDT",
            logoURI:
                "https://tokens.pancakeswap.finance/images/0x55d398326f99059fF775485246999027B3197955.png",
        },
    ];

    function handleCloseModal() {
        setIsVisible(false);
        setTimeout(() => handleClose(), 150);
    }

    function handleTokenSelectOnClick(tokenAddress) {
        handleTokenSelect(tokenAddress);
        handleCloseModal();
    }

    useEffect(() => {
        if (isToToken) {
            setFilteredTokenInfoList(trustedTokenList);
            return;
        }

        if (tokenSearchInput.length === 0) {
            setFilteredTokenInfoList([
                ...trustedTokenList,
                ...allTokenInfoList,
            ]);
            return;
        }

        let newFilteredTokenInfoList = [];
        const isBackspace =
            lastTokenSearchInput.length > tokenSearchInput.length;

        if (filteredTokenInfoList.length !== 0 && !isBackspace) {
            newFilteredTokenInfoList = filteredTokenInfoList.filter(
                (tokenInfo) =>
                    tokenInfo.symbol.toLowerCase().includes(tokenSearchInput) ||
                    tokenInfo.name.toLowerCase().includes(tokenSearchInput)
            );
        }

        if (newFilteredTokenInfoList.length === 0 || isBackspace) {
            newFilteredTokenInfoList = allTokenInfoList.filter(
                (tokenInfo) =>
                    tokenInfo.symbol.toLowerCase().includes(tokenSearchInput) ||
                    tokenInfo.name.toLowerCase().includes(tokenSearchInput)
            );
        }

        setFilteredTokenInfoList(newFilteredTokenInfoList);
        setlastTokenSearchInput(tokenSearchInput);
    }, [tokenSearchInput, allTokenInfoList]);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    useEffect(() => {
        if (isToToken) {
            return;
        }

        (async function () {
            const allTokenInfoList = await getAllTokenInfoFromAPI();
            const jointTokenInfoList = allTokenInfoList.flat();

            setAllTokenInfoList(jointTokenInfoList);
        })();
    }, []);

    return (
        <>
            <div
                className={
                    "fixed bg-[rgba(0,0,0,0.4)] z-10 w-screen h-screen top-0 left-0 duration-150 ease-out flex justify-center items-center " +
                    (isVisible ? "opacity-100" : "opacity-0")
                }
                onClick={() => handleCloseModal()}
            >
                <div
                    className="bg-[#141820] rounded-xl w-1/3 h-3/4 flex flex-col items-center p-4"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="w-full flex justify-end">
                        <i
                            className="fi fi-br-cross text-md cursor-pointer"
                            onClick={() => handleCloseModal()}
                        />
                    </div>
                    <div className="my-3 bg-[#0c0f13] rounded-xl h-10 w-full text-lg px-2 font-semibold focus:outline-1 focus:outline-[#162642] focus:outline-none flex">
                        <input
                            placeholder="XTM"
                            className="bg-transparent h-10 w-full px-2 outline-none placeholder:opacity-50"
                            onChange={(e) =>
                                setTokenSearchInput(
                                    e.target.value.toLowerCase()
                                )
                            }
                            disabled={isToToken}
                        />
                        <i className="fi fi-br-search text-lg flex justify-center items-center pr-1" />
                    </div>
                    <div className="rounded-xl overflow-hidden overflow-y-auto y-max w-full bg-[#0c0f13]">
                        {filteredTokenInfoList.map((tokenInfo, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex items-center gap-2 cursor-pointer rounded-xl py-1.5 px-3 hover:bg-[#1d2128] duration-100 w-full"
                                    onClick={() =>
                                        handleTokenSelectOnClick(
                                            tokenInfo.address
                                        )
                                    }
                                >
                                    {tokenInfo.logoURI ? (
                                        <LazyLoadImage
                                            src={tokenInfo.logoURI}
                                            className="min-w-8 h-8 rounded-full"
                                        />
                                    ) : (
                                        <i className="fi fi-br-interrogation text-3xl mb-1 min-w-8 h-8 flex items-center justify-center" />
                                    )}
                                    <div className="w-full overflow-hidden">
                                        <div className="whitespace-nowrap flex">
                                            {tokenInfo.symbol}
                                            {trustedTokenList.map(
                                                (trustedTokenInfo, index) => {
                                                    if (
                                                        trustedTokenInfo.address.toLowerCase() ===
                                                        tokenInfo.address.toLowerCase()
                                                    ) {
                                                        return (
                                                            <i
                                                                key={index}
                                                                className="fi fi-br-check text-base text-green-500 flex pl-1 pt-0.5"
                                                            />
                                                        );
                                                    }
                                                }
                                            )}
                                        </div>
                                        <div className="whitespace-nowrap w-full overflow-hidden text-ellipsis hover:overflow-visible">
                                            {tokenInfo.name}
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-end">
                                        <div
                                            className="w-max flex items-center justify-end gap-2 border-b-[1px] hover:border-[#c2c2c2] border-transparent duration-100"
                                            onClick={() => {
                                                window
                                                    .open(
                                                        `https://bscscan.com/token/${tokenInfo.address}`,
                                                        "_blank"
                                                    )
                                                    .focus();
                                            }}
                                        >
                                            <div>
                                                {tokenInfo.address.substring(
                                                    0,
                                                    6
                                                )}
                                                ...
                                                {tokenInfo.address.substring(
                                                    tokenInfo.address.length - 4
                                                )}
                                            </div>
                                            <i className="fi fi-br-arrow-up-right-from-square text-base pt-0.5" />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
