import * as Yup from "yup";

export const tokenSwapSchema = Yup.object().shape({
    fromTokenAmount: Yup.number()
        .required("Amount is required")
        .positive("Amount must be positive")
        .min(
            0.0000_0000_0000_0000_01,
            "Amount must be greater than 0 and less than 18 decimals"
        )
        .typeError("Amount must be a number"),
    toTokenAmount: Yup.number()
        .required("Amount is required")
        .positive("Amount must be positive")
        .min(
            0.0000_0000_0000_0000_01,
            "Amount must be greater than 0 and less than 18 decimals"
        )
        .typeError("Amount must be a number"),
});
