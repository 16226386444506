import React from "react";
import { numberToBytes } from "viem";

export default function PriceModalByMarket({
    logo,
    name,
    price,
    volume,
    volumePercent,
}) {
    function formatNumber(number) {
        return new Intl.NumberFormat("en-US").format(number);
    }

    return (
        <div className="p-3 rounded-xl bg-[#141820] hover:bg-[#1a202a] duration-150">
            <div className="flex items-center">
                <img src={logo} alt="logo" className="w-5 h-5" />
                <div className="ml-3 text-l font-semibold">{name}</div>
            </div>
            <div>
                <div className="flex justify-between text-2xl">
                    ${formatNumber(price)}
                </div>
                <div>
                    24h Vol ${formatNumber(volume)} ({volumePercent}%)
                </div>
            </div>
        </div>
    );
}
