import React from "react";

export default function SwapSummary({
    minimumReceived,
    priceBefore,
    priceAfter,
    swapFee,
    routeArray,
    tokenSymbol,
}) {
    function formatNumber(number) {
        return new Intl.NumberFormat("en-US").format(number);
    }

    function arrowToRight(key) {
        return (
            <div className="flex justify-center" key={key}>
                <i className="fi fi-br-caret-right pt-0.5" />
            </div>
        );
    }

    function getChangePercentage() {
        return Math.abs(
            ((priceAfter - priceBefore) / priceBefore) * 100
        ).toFixed(2);
    }

    function getRoute() {
        return routeArray.map((route, index) => {
            if (index === routeArray.length - 1) {
                return <div key={index}>{route}</div>; // Last element
            }

            // Have to use <div> to prevent "key required for each child" error
            return (
                <div className="flex" key={index}>
                    {route} {arrowToRight(index)}
                </div>
            );
        });
    }

    return (
        <div className="flex flex-col justify-center gap-5 w-full">
            <div>
                <div>Minimum Received:</div>
                <div>
                    {formatNumber(minimumReceived) || "-"} {tokenSymbol}
                </div>
            </div>
            <div>
                <div>Price Impact:</div>
                <div className="flex">
                    {priceBefore != undefined && priceAfter != undefined && (
                        <div className="flex gap-2 justify-center">
                            <div className="flex">
                                ${formatNumber(priceBefore)} {arrowToRight()} $
                                {formatNumber(priceAfter)}
                            </div>
                            {priceAfter > priceBefore ? (
                                <div className="text-green-500">
                                    (+{getChangePercentage()}%)
                                </div>
                            ) : (
                                <div className="text-red-500">
                                    (-{getChangePercentage()}%)
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div>
                <div>Swap Fee:</div>
                <div>
                    {formatNumber(swapFee) || "-"} {tokenSymbol}
                </div>
            </div>
            <div>
                <div>Route:</div>
                <div className="flex flex-wrap">
                    {routeArray?.length !== 0 ? getRoute() : "-"}
                </div>
            </div>
        </div>
    );
}
