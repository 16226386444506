import axios from "axios";

const TOKEN_INFO_URL_LIST = [
    "https://tokens.pancakeswap.finance/coingecko.json",
    "https://tokens.pancakeswap.finance/pancakeswap-extended.json",
    "https://tokens.pancakeswap.finance/pancakeswap-bnb-mm.json",
];

export async function getAllTokenInfoFromAPI() {
    const tokenInfoListRequestResponse = await Promise.all(
        TOKEN_INFO_URL_LIST.map((url) => axios.get(url))
    );

    const tokenInfoList = tokenInfoListRequestResponse.map(
        (requestResponse) => {
            if (requestResponse.status === 200) {
                return requestResponse?.data?.tokens;
            }
        }
    );

    return tokenInfoList;
}

async function getTokenInfoFromAPI(tokenAddress) {
    const tokenInfoList = await getAllTokenInfoFromAPI();
    let tokenInfoForReturn = null;

    tokenInfoList.map((tokenInfoSublist) => {
        tokenInfoSublist.map((tokenInfo) => {
            if (tokenInfo.address.toLowerCase() === tokenAddress) {
                return (tokenInfoForReturn = tokenInfo);
            }
        });

        if (tokenInfoForReturn) {
            return;
        }
    });

    return tokenInfoForReturn;
}

function getTokenInfoListFromLocalStorage() {
    const tokenInfoList = JSON.parse(localStorage.getItem("tokenInfoList"));
    return tokenInfoList;
}

function getTokenInfoFromLocalStorage(tokenAddress) {
    const tokenInfoList = getTokenInfoListFromLocalStorage();

    if (!tokenInfoList) {
        return null;
    }

    const tokenInfo = tokenInfoList.find(
        (tokenInfo) => tokenInfo.address === tokenAddress
    );

    return tokenInfo;
}

function saveTokenInfoToLocalStorage(tokenInfo) {
    tokenInfo.address = tokenInfo.address.toLowerCase();

    const currentTokenInfoList = getTokenInfoListFromLocalStorage();
    if (!currentTokenInfoList) {
        localStorage.setItem("tokenInfoList", JSON.stringify([tokenInfo]));
        return;
    }

    const tokenInfoList = [...currentTokenInfoList, tokenInfo];
    localStorage.setItem("tokenInfoList", JSON.stringify(tokenInfoList));
}

export async function getTokenInfo(tokenAddressRaw) {
    if (!tokenAddressRaw) {
        // console.log("Token address not provided");
        return null;
    }

    const tokenAddress = tokenAddressRaw.toLowerCase();
    if (!tokenAddress || tokenAddress.length !== 42) {
        // console.log("Invalid token address");
        return null;
    }

    const tokenInfoFromLocalStorage =
        getTokenInfoFromLocalStorage(tokenAddress);

    if (tokenInfoFromLocalStorage) {
        // console.log("Token info found in local storage");
        return tokenInfoFromLocalStorage;
    }

    const tokenInfoFromAPI = await getTokenInfoFromAPI(tokenAddress);

    if (!tokenInfoFromAPI) {
        // console.log("Token info not found in API");
        return null;
    }

    // console.log("Token info found in API");
    saveTokenInfoToLocalStorage(tokenInfoFromAPI);
    return tokenInfoFromAPI;
}

// search cache, if not found, search from API, save to cache, return
